<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner8.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner8.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner8.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="https://cdn.pocketfully.com/assets/partner8.png" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
