<div class="footer-area footer-style-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="https://cdn.pocketfully.com/assets/black-logo.png" alt="logo">
                    </a>
                    <p>Keep a record of your income and expenses, plan your future movements and analyze your spending habits to optimize your cash flow and save money.</p>
                    <ul class="social-links">
                        <li><a routerLink="/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a routerLink="/" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <!--<li><a href="#" target="_blank">
                          <i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>Company</h3>
                    <ul class="links-list">
                        <li><a routerLink="/">Home</a></li>
                        <!--
                        <li><a routerLink="/features-2">Core Services</a></li>
                        <li><a routerLink="/privacy-policy">Refund Policy</a></li>
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/feedback">Reviews</a></li>
                        -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="links-list">

                        <!--
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        -->
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">FAQ's</a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Useful Links</h3>
                    <ul class="links-list">
                        <li><a routerLink="/privacy-policy">Privacy
                          Policy</a></li>
                          <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <!--
                        <li><a routerLink="/privacy-policy">Return Policy</a></li>
                        <li><a routerLink="/how-it-works">How It Works?</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        -->


                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Learning</h3>
                    <ul class="links-list">

                        <li><a routerLink="/">Help Center</a></li>

                    </ul>
                </div>
            </div>

        </div>
        <div class="copyright-area">
            <p>Copyright @2021 <strong>Pocketfully</strong>. All Rights Reserved</p>
        </div>
    </div>
</div>
