<div class="team-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">OUR EXPERTS</span>
            <h2>Meet With Our Team Member</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-1"></div>
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Lead</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-2"></div>
                    </div>
                    <div class="content">
                        <h3>Ben Stokes</h3>
                        <span>Art Director</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-3"></div>
                    </div>
                    <div class="content">
                        <h3>Ellyse Perry</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-4"></div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Development</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-5"></div>
                    </div>
                    <div class="content">
                        <h3>Suzie Bates</h3>
                        <span>Front-End Development</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                <div class="single-team-box">
                    <div class="image">
                        <div class="bg-6"></div>
                    </div>
                    <div class="content">
                        <h3>David Warner</h3>
                        <span>Head of Team</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>