<div class="features-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-content">
                    <span class="sub-title">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p>
                    <div class="btn-box">
                        <a routerLink="/pricing" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-2" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="features-lists">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>High Resolution</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </li>
                        <li>
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Retina Ready Screen</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </li>
                        <li>
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </li>
                        <li>
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Easy Editable Data</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>