<div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                    </div>
                    <h3>User Friendly</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg2">
                        <i class="ri-award-line"></i>
                    </div>
                    <h3>Award Winning App</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg3">
                        <i class="ri-fingerprint-line"></i>
                    </div>
                    <h3>Privacy Protected</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg4">
                        <i class="ri-vip-diamond-line"></i>
                    </div>
                    <h3>Lifetime Update</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overview-area">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-content">
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Trusted and Reliable</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/sign-in" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-2" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-image bg2">
                    <img src="assets/img/overview2.jpg" alt="overview">
                </div>
            </div>
        </div>
    </div>
</div>