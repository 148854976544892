<div class="overview-area">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-content">
                    <h2>Healthy financial life</h2>
                    <p>Keep a record of your income and expenses, plan your future movements and analyze your spending habits to optimize your cash flow, so you can increase your ability to save some money. This helps you maintain a healthy financial life, this means having more peace and time for the things that bring happiness and balance to everyday life.</p>
                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Includes all features, full access from your smartphone, tablet or web browser safely anywhere and anytime.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Easy to understand</h3>
                            <p>Diagrams and graphs give you a comprehensive understanding of your spending habits and your money's performance, making it really useful for personal finances.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Sync all your devices</h3>
                            <p>Keep track of your spending everywhere, Pocketfully is a brilliant application runs on a number of different android phones and tablets, bringing you secure up-to-date, touch-enabled mobile access to your information.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                      <a href="https://app.pocketfully.com" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-image bg1">
                    <img src="https://cdn.pocketfully.com/assets/overview1.jpg" alt="overview">
                </div>
            </div>
        </div>
    </div>
</div>
