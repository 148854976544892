<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>App Download</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>App Download</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="app-download-area ptb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>