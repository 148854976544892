<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Meet With Our Team Member</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="team-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team1.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>James Andy</h3>
                        <span>CEO & Founder</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team2.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team3.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Web Designer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team4.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Alina Aly</h3>
                        <span>SEO Consultant</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team5.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Ross Taylor</h3>
                        <span>Front-End Developer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team6.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Meg Lanning</h3>
                        <span>Art Director</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team7.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Du Plessis</h3>
                        <span>Designer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team/team8.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Ellyse Perry</h3>
                        <span>Development</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-one></app-footer-style-one>