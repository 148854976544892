import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-free-trial-style-one',
  templateUrl: './free-trial-style-one.component.html',
  styleUrls: ['./free-trial-style-one.component.scss']
})
export class FreeTrialStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
