import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-modern-page',
  templateUrl: './about-modern-page.component.html',
  styleUrls: ['./about-modern-page.component.scss']
})
export class AboutModernPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
