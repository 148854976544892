<div class="container">
    <div class="section-title">
        <span class="sub-title">KEY FEATURES</span>
        <h2>Plan your future expenses and analyze your spending habits</h2>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon">
                    <i class="ri-eye-line"></i>
                </div>
                <h3>Scan your expense receipts</h3>
                <p>Capture receipts with your smartphone, take a photo of your receipt and forget to lose it, centralize all your receipts and always have them on hand for any future clarification</p>
            </div>
        </div>
        <!--
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon bg2">
                    <i class="ri-stack-line"></i>
                </div>
                <h3>Track your spending locations</h3>
                <p>Activate your GPS to track the locations of your expenses or income. Learn from your own history, Pocketfully can track all your income and expenses that come each month, so you can analyze your habits and learn to spend on things that really matter.</p>
            </div>
        </div>
        -->
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon bg3">
                    <i class="ri-leaf-line"></i>
                </div>
                <h3>Bills reminders</h3>
                <p>Never forget to pay a bill again, pocketfully provides you with a simple way to set reminder notifications to ensure invoices are always paid on time. Reminders will keep you informed to avoid any additional payment fee. </p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon bg4">
                    <i class="ri-secure-payment-line"></i>
                </div>
                <h3>Set your own limits</h3>
                <p>Set your own spending limit by category, sometimes it’s hard to know when you’ve went over the line.Set yourself a category budget to keep an eye on your spending, you’ll be saving money when the end of the month approaches.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon bg5">
                    <i class="ri-cloud-line"></i>
                </div>
                <h3>Set your own goals</h3>
                <p>Setting your own savings goals will help you start saving moneym just name your goal, determine how much you can save each month and start saving.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="features-item">
                <div class="icon bg6">
                    <i class="ri-pie-chart-2-line"></i>
                </div>
                <h3>Monthly planner</h3>
                <p>Stay on top of your finances with a monthly plan, the monthly calendar clearly shows when and how much you spent, it is also your first step to achieving healthy financial goals, this gives you an advantage to achieve healthy personal financing. </p>
            </div>
        </div>
        <!--
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="features-item">
              <div class="icon">
                  <i class="ri-eye-line"></i>
              </div>
              <h3>Collaborative budgets</h3>
              <p>Create and manage your shared budgets with your friends, family, partner, so you and your other half can see your collective progress on paying off credit cards, student loans or any shared budget. </p>
          </div>
      </div>
      
      <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="features-item">
              <div class="icon bg2">
                  <i class="ri-stack-line"></i>
              </div>
              <h3>Voice commands</h3>
              <p>You can use voice commands to control your personal finances. Voice commands are a quick and easy way to enter your expenses or income, so you will never lose any transaction. When you enable voice commands and spoken notifications, you can interact with Pocketfully in a new and interesting way.</p>
          </div>
      </div>
    -->
      <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="features-item">
              <div class="icon bg3">
                  <i class="ri-leaf-line"></i>
              </div>
              <h3>Recurring bills</h3>
              <p>Examples include cable bills, cell phone bills, gym membership fees, utility bills and magazine subscriptions. This will help you prepare next month's budget, so you will always be one step ahead in your personal finances.</p>
          </div>
      </div>
    </div>
</div>
