<app-navbar-style-one></app-navbar-style-one>

<div class="error-area pb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/error.png" alt="image">
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn">Back To Home</a>
        </div>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-footer-style-one></app-footer-style-one>