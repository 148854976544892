<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon">
                        <i class="ri-eye-line"></i>
                    </div>
                    <h3>High Resolution</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon bg2">
                        <i class="ri-stack-line"></i>
                    </div>
                    <h3>Retina Ready Screen</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon bg3">
                        <i class="ri-leaf-line"></i>
                    </div>
                    <h3>Easy Editable Data</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon bg4">
                        <i class="ri-secure-payment-line"></i>
                    </div>
                    <h3>Fully Secured</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon bg5">
                        <i class="ri-cloud-line"></i>
                    </div>
                    <h3>Cloud Storage</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-item">
                    <div class="icon bg6">
                        <i class="ri-pie-chart-2-line"></i>
                    </div>
                    <h3>Responsive Ready</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="features-area ptb-100 bg-F7F7FF">
    <app-features-style-one></app-features-style-one>
</div>

<div class="features-area pt-100 pb-75">
    <app-features-style-four></app-features-style-four>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-one></app-footer-style-one>