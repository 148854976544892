<div class="software-integrations-area ptb-100">
    <div class="container">
        <div class="software-integrations-inner bg-gradient-color">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="software-integrations-content white-color">
                        <span class="sub-title">SOFTWARE INTEGRATIONS</span>
                        <h2>Easy & Perfect Solution With Latest Software Integrations</h2>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. never missyour chance its just began. Backup just log in with your mail account.</p>
                        <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                        <a routerLink="/sign-in" class="default-btn">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="software-integrations-list">
                        <img src="assets/img/shape/border.png" alt="border">
                        <ul>
                            <li><img src="assets/img/software-integrations/atlassian.png" class="atlassian" alt="atlassian"></li>
                            <li><img src="assets/img/software-integrations/skype.png" class="skype" alt="skype"></li>
                            <li><img src="assets/img/software-integrations/gdrive.png" class="gdrive" alt="gdrive"></li>
                            <li><img src="assets/img/software-integrations/slack.png" class="slack" alt="slack"></li>
                            <li><img src="assets/img/software-integrations/jira.png" class="jira" alt="jira"></li>
                            <li><img src="assets/img/software-integrations/frame.png" class="frame" alt="frame"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>