import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-screenshots-style-six',
    templateUrl: './screenshots-style-six.component.html',
    styleUrls: ['./screenshots-style-six.component.scss']
})
export class ScreenshotsStyleSixComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}