<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
  <div class="container">
      <div class="page-title-content">
          <h2>Terms & Conditions</h2>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li>Terms & Conditions</li>
          </ul>
      </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="terms-conditions-area ptb-100">
    <div class="container">
        <div class="terms-conditions-content">

            <h5>Acceptance of the Terms of Service</h5>
            <p>Welcome to the website of Pocketfully” LLC (“Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Service”), govern your access to and use of the budgeting software available through our apps, software, and the website pocketfully.com, any associated content, including receipt of educational materials and communications to manage your subscription, functionality and services (the “Website”), whether as a guest or a registered user.</p>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
