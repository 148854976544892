<div class="blog-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">BLOG POST</span>
            <h2>Latest Article From Our Blog</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="https://cdn.pocketfully.com/assets/blog1.jpg" alt="blog">
                        </a>
                        <a routerLink="/blog-grid" class="tag">Branding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i> April 14, 2021</li>
                            <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(0) Comment</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Branding involves developing strategy to create a point of differentiation</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="https://cdn.pocketfully.com/assets/blog2.jpg" alt="blog">
                        </a>
                        <a routerLink="/blog-grid" class="tag">Agency</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i> April 13, 2021</li>
                            <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(4) Comment</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Design is a plan or specification for the construction of an object</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="https://cdn.pocketfully.com/assets/blog3.jpg" alt="blog">
                        </a>
                        <a routerLink="/blog-grid" class="tag">Marketing</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i> April 12, 2021</li>
                            <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(2) Comment</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Branding involves developing strategy to create a point</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
