<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">ABOUT APZEL</span>
                    <h2>A Few Words About Us</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <div class="features-text">
                        <h6>Our App</h6>
                        <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                    </div>
                    <div class="features-text">
                        <h6>Our Mission</h6>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/app/app-img7.png" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="funfacts-area pb-75">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<app-progress-style-two></app-progress-style-two>

<app-user-stats></app-user-stats>

<app-hometwo-software-integrations></app-hometwo-software-integrations>

<app-team-style-one></app-team-style-one>

<div class="feedback-area pb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>