<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-features></app-hometwo-features>

<app-hometwo-key-features></app-hometwo-key-features>

<app-progress-style-two></app-progress-style-two>

<app-intro-video></app-intro-video>

<div class="screenshots-area pb-100">
    <app-screenshots-style-two></app-screenshots-style-two>
</div>

<app-hometwo-software-integrations></app-hometwo-software-integrations>

<div class="feedback-area pt-100">
    <app-feedback-style-two></app-feedback-style-two>
</div>

<div class="pricing-area ptb-100">
    <app-pricing-style-two></app-pricing-style-two>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="shape"></div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
</div>

<app-free-trial-style-two></app-free-trial-style-two>

<div class="partner-area ptb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>