<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-center m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-content">
                    <span class="sub-title">Get your 14 days free trial</span>
                    <h1>Spend smart and save money</h1>
                    <p>Pocketfully a brilliant personal finance application, an easy and convenient way to save money, plan your savings goals and effectively track expenses and meet the budget, from your smartphone, tablet or web browser safely any time and any place. </p>
                    <a routerLink="/pricing" class="default-btn">Start Free Trial</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-image">
                    <img src="https://cdn.pocketfully.com/assets/progress-main.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
    <div class="banner-shape6"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="banner-shape7"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="banner-shape8"><img src="assets/img/shape/shape11.png" alt="image"></div>
</div>
