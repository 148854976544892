<div class="gradient-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="gradient-banner-content">
                    <h1>Manage All of Your Stuff Using a Apzel</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                    <form>
                        <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                        <button type="submit" class="default-btn">Get Started</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="gradient-banner-image" data-aos="fade-up">
                    <img src="assets/img/banner/banner-img3.png" alt="banner-img">
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="banner-shape2"><img src="assets/img/shape/shape7.png" alt="image"></div>
</div>