<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-4 col-md-12">
            <div class="testimonials-image">
                <img src="assets/img/woman.jpg" alt="testimonials">
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="testimonials-content">
                <span class="sub-title">CLIENT REVIEWS</span>
                <h2>Customer Reviews About Our App</h2>
            </div>
            <div class="testimonials-slides">
                <owl-carousel-o [options]="testimonialsSlides">
                    <ng-template carouselSlide>
                        <div class="content">
                            <h3>Londynn Vargas</h3>
                            <span>Spotify Developer</span>
                            <p>Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="content">
                            <h3>James Andy</h3>
                            <span>PHP Developer</span>
                            <p>Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>JavaScript Developer</span>
                            <p>Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>