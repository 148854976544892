import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-app-style-three',
  templateUrl: './download-app-style-three.component.html',
  styleUrls: ['./download-app-style-three.component.scss']
})
export class DownloadAppStyleThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
