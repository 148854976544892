<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
  <div class="container">
      <div class="page-title-content">
          <h2>Privacy Policy</h2>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li>Privacy Policy</li>
          </ul>
      </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy-content">
          <h6>Your privacy
          </h6>
          <p>We understand that your privacy is an important concern, especially for a personal finance site like Pocketfully. Pocketfully, Inc. (Pocketfully”), the creator of Pocketfully, is committed to protecting the privacy of all of our users. This Privacy and Security Policy explains how Pocketfully protects the information provided to us through the website www.pocketfully.com and through our mobile apps.</p>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
