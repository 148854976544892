import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-style-one',
  templateUrl: './progress-style-one.component.html',
  styleUrls: ['./progress-style-one.component.scss']
})
export class ProgressStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
