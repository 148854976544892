<div class="profile-authentication-area">
    <div class="logo">
        <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="logo"></a>
    </div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="forget-password-form">
                    <h2>Forget Password</h2>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Username or email">
                        </div>
                        <button type="submit">Send Reset Link</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ul class="social-links d-flex align-items-center justify-content-center">
        <li><span>Follow Us On:</span></li>
        <li><a href="#" class="facebook" target="_blank"><i class="ri-facebook-fill"></i></a></li>
        <li><a href="#" class="twitter" target="_blank"><i class="ri-twitter-fill"></i></a></li>
        <li><a href="#" class="linkedin" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
        <li><a href="#" class="instagram" target="_blank"><i class="ri-instagram-line"></i></a></li>
    </ul>
</div>