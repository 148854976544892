<div class="screenshots-area bg-black-color ptb-100">
    <div class="container">
        <div class="section-title color-white">
            <span class="sub-title">APP SCREENS</span>
            <h2>Beautifully Crafted All App Screenshots</h2>
        </div>
        <div class="screenshots-slides">
            <owl-carousel-o [options]="screenshotsSlides">
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots1.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots2.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots3.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots4.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots5.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-item">
                        <img src="https://cdn.pocketfully.com/assets/screenshots4.png" alt="screenshots">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
