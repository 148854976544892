<div class="screenshots-area bg-black-color ptb-100">
    <div class="container">
        <div class="section-title color-white">
            <span class="sub-title">SCREENSHOTS</span>
            <h2>Easily Customize in One Minute</h2>
        </div>
        <div class="screenshots-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Budget Overview">
                    <img src="assets/img/screenshots/screen5.jpg" alt="screenshots">
                </ngx-tab>
                <ngx-tab tabTitle="Create & Adjust">
                    <img src="assets/img/screenshots/screen6.jpg" alt="screenshots">
                </ngx-tab>
                <ngx-tab tabTitle="View Reports">
                    <img src="assets/img/screenshots/screen7.jpg" alt="screenshots">
                </ngx-tab>
                <ngx-tab tabTitle="Integrations">
                    <img src="assets/img/screenshots/screen8.jpg" alt="screenshots">
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>