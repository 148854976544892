<div class="container">
    <div class="section-title">
        <span class="sub-title">PRICING TABLE</span>
        <h2>No Hidden Charge Applied, Choose Your Plan</h2>
    </div>
    <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="title">
                    <h3>Basic</h3>
                    <p>Powerful & awesome elements</p>
                </div>
                <div class="price">
                    $39 <span>/Month</span>
                </div>
                <a routerLink="/" class="default-btn">Purchase Plan</a>
                <ul class="features-list">
                    <li><i class="ri-check-line"></i> Up to 10 Website</li>
                    <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                    <li><i class="ri-check-line"></i> 10 GB Dedicated Hosting Free</li>
                    <li><i class="ri-check-line"></i> 24/7 Support</li>
                    <li><i class="ri-close-line"></i> SEO Optimized</li>
                    <li><i class="ri-close-line"></i> Live Support</li>
                </ul>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box active">
                <div class="title">
                    <h3>Team</h3>
                    <p>Powerful & awesome elements</p>
                </div>
                <span class="popular">Most Popular</span>
                <div class="price">
                    $49 <span>/Month</span>
                </div>
                <a routerLink="/" class="default-btn">Purchase Plan</a>
                <ul class="features-list">
                    <li><i class="ri-check-line"></i> Up to 200 Website</li>
                    <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                    <li><i class="ri-check-line"></i> 25 GB Dedicated Hosting Free</li>
                    <li><i class="ri-check-line"></i> 24/7 Support</li>
                    <li><i class="ri-check-line"></i> SEO Optimized</li>
                    <li><i class="ri-close-line"></i> Live Support</li>
                </ul>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="title">
                    <h3>Business</h3>
                    <p>Powerful & awesome elements</p>
                </div>
                <div class="price">
                    $59 <span>/Month</span>
                </div>
                <a routerLink="/" class="default-btn">Purchase Plan</a>
                <ul class="features-list">
                    <li><i class="ri-check-line"></i> Up to 500 Website</li>
                    <li><i class="ri-check-line"></i> Lifetime Free Support</li>
                    <li><i class="ri-check-line"></i> 50 GB Dedicated Hosting Free</li>
                    <li><i class="ri-check-line"></i> 24/7 Support</li>
                    <li><i class="ri-check-line"></i> SEO Optimized</li>
                    <li><i class="ri-check-line"></i> Live Support</li>
                </ul>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="see-more-pricing color-black">
                <a routerLink="/pricing" class="link-btn">See All Pricing Plan</a>
            </div>
        </div>
    </div>
</div>