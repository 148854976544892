<div class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">ABOUT US</span>
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <div class="features-text">
                        <h6>Trusted and Reliable</h6>
                        <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                    </div>
                    <div class="features-text">
                        <h6>Cloud Storage</h6>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/sign-up" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/app/app-img7.png" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>