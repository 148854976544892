<div class="banner-wrapper-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="banner-wrapper-content">
                    <span class="sub-title">Personal Finance Management</span>
                    <h1>Spend smart and save money</h1>
                    <p>Pocketfully a brilliant personal finance application, an easy and convenient way to save money, plan your savings goals and effectively track expenses and meet the budget, from your smartphone, tablet or web browser safely any time and any place. </p>
                    <a href="https://app.pocketfully.com" class="default-btn">Get Started</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="banner-wrapper-image">
                    <img src="https://cdn.pocketfully.com/assets/banner-img1.png" alt="banner-img">
                    <img src="https://cdn.pocketfully.com/assets/banner-img2.png" data-aos="fade-left" alt="banner-img">
                </div>
            </div>
        </div>
    </div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
    <div class="shape14"><img src="assets/img/shape/shape17.png" alt="shape"></div>
    <div class="shape15"><img src="assets/img/shape/shape18.png" alt="shape"></div>
</div>
