<app-navbar-style-four></app-navbar-style-four>

<app-homesix-banner></app-homesix-banner>

<div class="partner-area ptb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-homesix-about></app-homesix-about>

<div class="funfacts-area pb-75">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<div class="features-area pb-75">
    <app-features-style-five></app-features-style-five>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-screenshots-style-six></app-screenshots-style-six>

<div class="testimonials-area ptb-100">
    <app-feedback-style-five></app-feedback-style-five>
</div>

<div class="free-trial-area">
    <app-free-trial-style-three></app-free-trial-style-three>
</div>

<div class="pricing-area pt-100">
    <app-pricing-style-two></app-pricing-style-two>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="shape"></div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
</div>

<app-blog></app-blog>

<app-footer-style-three></app-footer-style-three>