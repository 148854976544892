<div class="main-banner-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="main-banner-content">
                    <div class="content">
                        <span class="sub-title">#Get your 14 days free trial</span>
                        <h1>The Revolutionary App That Makes Your Life Easier Than Others Apps</h1>
                        <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
                    </div>
                    <div class="trusted-by">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-12">
                                <span class="title">Trusted by:</span>
                            </div>
                            <div class="col-lg-10 col-md-12">
                                <div class="trusted-by-slides">
                                    <owl-carousel-o [options]="trustedBySlides">
                                        <ng-template carouselSlide>
                                            <div class="item">
                                                <img src="assets/img/partner/partner3.png" alt="partner">
                                            </div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <div class="item">
                                                <img src="assets/img/partner/partner1.png" alt="partner">
                                            </div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <div class="item">
                                                <img src="assets/img/partner/partner5.png" alt="partner">
                                            </div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <div class="item">
                                                <img src="assets/img/partner/partner6.png" alt="partner">
                                            </div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                            <div class="item">
                                                <img src="assets/img/partner/partner2.png" alt="partner">
                                            </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="main-banner-image" data-aos="fade-up" data-aos-duration="2000">
                    <img src="assets/img/banner/banner-app1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape-overlay"></div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="banner-shape2"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="banner-shape4"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="banner-shape5"><img src="assets/img/shape/shape11.png" alt="image"></div>
</div>
