<div class="container">
    <div class="section-title">
        <span class="sub-title">CLIENT REVIEWS</span>
        <h2>Some of Our Happy Customer Reviews About Our Products</h2>
    </div>
    <div class="feedback-slides">
        <owl-carousel-o [options]="feedbackSlides">
            <ng-template carouselSlide>
                <div class="single-feedback-box">
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="https://cdn.pocketfully.com/assets/user1.jpg" alt="user">
                            <div class="title">
                                <h3>Deanna Hodges</h3>
                                <span>Spotify Developer</span>
                            </div>
                        </div>
                    </div>
                    <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                    <div class="rating d-flex align-items-center justify-content-between">
                        <h5>Theme Customization</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-box">
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="https://cdn.pocketfully.com/assets/user2.jpg" alt="user">
                            <div class="title">
                                <h3>Londynn Vargas</h3>
                                <span>PHP Developer</span>
                            </div>
                        </div>
                    </div>
                    <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                    <div class="rating d-flex align-items-center justify-content-between">
                        <h5>Customer Support</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-box">
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="https://cdn.pocketfully.com/assets/user3.jpg" alt="user">
                            <div class="title">
                                <h3>James Andy</h3>
                                <span>Moodle Developer</span>
                            </div>
                        </div>
                    </div>
                    <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                    <div class="rating d-flex align-items-center justify-content-between">
                        <h5>Responsive Design</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-line"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-box">
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="https://cdn.pocketfully.com/assets/user4.jpg" alt="user">
                            <div class="title">
                                <h3>David Warner</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                    <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                    <div class="rating d-flex align-items-center justify-content-between">
                        <h5>Design Quality</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-half-line"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
