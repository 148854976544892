<div class="gradient-main-banner">
    <div class="container-fluid">
        <div class="gradient-main-banner-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <span class="sub-title">#Get your 14 days free trial</span>
                        <h1>The Revolutionary App That Makes Your Life Easier Than Others App</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                        <a routerLink="/sign-in" class="default-btn">Start Free Trial</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="assets/img/banner/banner-img4.png" alt="banner-img">
                    </div>
                </div>
            </div>
            <div class="banner-shape2"><img src="assets/img/shape/shape7.png" alt="image"></div>
            <div class="banner-shape9"><img src="assets/img/shape/shape21.png" alt="image"></div>
            <div class="banner-shape10"><img src="assets/img/shape/shape21.png" alt="image"></div>
            <div class="banner-shape11"><img src="assets/img/shape/shape22.png" alt="image"></div>
            <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
        </div>
    </div>
</div>